
.card-container {
    width: 300px;
    overflow: hidden;
    box-shadow: #282c34 0px 0px 10px;
    border-radius: 14px;
    margin: 10px;
    padding: 10px;
    background-color: #181A1B;
}

.image-container img{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 14px;
}
.card-content{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    /*height: fit-content;*/
    color: white;
}

.card-title > h3{
    font-family: Inter;
    font-size: 20px;
    font-weight: 800;
    /*line-height: 14px;*/
    letter-spacing: -0.04em;
    text-align: left;
    left: 10px;
    top: 6.5px;
    color: white;
}

.card-date-address > p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    /*line-height: 20px;*/
    text-align: left;
    letter-spacing: -0.1px;
}

.details-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    left: 10px;
}

.details h3{
    font-family: Inter;
    font-size: 18px;
    font-weight: 800;
    /*line-height: 24px;*/
    letter-spacing: -0.04em;
    text-align: left;
    /*height: 24px;*/
    left: 10px;
    /*top: 6.5px;*/
    color: white;
}


.buy-button button{
    background-color: #dd2222;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    width: 100%;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

